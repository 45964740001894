type ParamKey = string;
// undefined is used to remove the param
type ParamValue = string | number | boolean | string[] | number[] | undefined;

export interface Params {
  [key: ParamKey]: ParamValue;
}

export const URL_PARAM_QUERY = 'q';

// this function will update the url params without triggering a page reload
export const updateUrlParams = (params: Params) => {
  let queryString = '';

  for (const [param, value] of Object.entries(params)) {
    if (value && Array.isArray(value) && value.length > 0) {
      const paramUrl = value.map((v) => `${param}=${v}`).join('&');
      queryString += `&${paramUrl}`;
    } else if (value && !Array.isArray(value)) {
      queryString += `&${param}=${value}`;
    }
  }

  const newURL = new URL(window.location.href);

  if (queryString) {
    queryString = `?${queryString.slice(1)}`; // remove the first '&'
    newURL.search = queryString;
  } else {
    newURL.search = '';
  }

  window.history.replaceState({ path: newURL.href }, '', newURL.href);
};

export const normalizeUrlPath = (path: string) => {
  const norm = path.replace(/^\/(en|ja)\//, '/');
  return norm.endsWith('/') ? norm : `${norm}/`;
};
